import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { z } from 'zod'
import { ActionType } from '@/components/kol/navigation/kol-actions-dropdown'

export const tabKeySchema = z.enum([
  'overview',
  'contactInfo',
  'cooperationHistory',
  'quotation',
  'notes',
  'footprint',
  'custom',
])
type TabKey = z.infer<typeof tabKeySchema>

interface KolCooperationDetailDrawerState {
  open: boolean
  kolId?: string
  dropdownActions: ActionType[]
  activeTabKey: TabKey
}

const initialState: KolCooperationDetailDrawerState = {
  open: false,
  activeTabKey: 'overview',
  dropdownActions: [
    'unlock',
    'addKolToCollection',
    'addKolToCampaign',
    'addKolToReport',
    'removeFromDirectory',
  ],
}

const kolCooperationDetailDrawerSlice = createSlice({
  name: 'kolCooperationDetailDrawer',
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload
    },
    setKolId: (state, action: PayloadAction<string>) => {
      if (state.kolId !== action.payload) {
        state.activeTabKey = 'overview'
      }

      state.kolId = action.payload
    },
    setActiveTabKey: (state, action: PayloadAction<TabKey>) => {
      state.activeTabKey = action.payload
    },
  },
})

export const { setOpen, setKolId, setActiveTabKey } =
  kolCooperationDetailDrawerSlice.actions

export default kolCooperationDetailDrawerSlice.reducer
