import { z } from 'zod'
import { AllowedGender } from '@/components/common/selectors/gender-radio-group'
import { AllCountryCode } from '@/constants/country-code'
import { I18nId } from '@/i18n/config'
import { PaginatedRequest } from '@/types/api'
import { listResourceSchema } from '@/types/schema/api/list-resource-schema'
import { quickSearchKolSchema } from '@/types/schema/search'
import { PlatformShortcode } from '@/utils/convert-platform'

export const kolAssetCatalogAvailablePlatforms = [
  PlatformShortcode.Instagram,
  PlatformShortcode.Facebook,
  PlatformShortcode.YouTube,
  PlatformShortcode.TikTok,
  PlatformShortcode.Twitter,
] as const
export interface FetchKolAssetCatalogRequest extends PaginatedRequest {
  uuids?: string[]
  platforms?: PlatformShortcode[]
  customized_tags_names?: string
  country_code?: AllCountryCode
  gender?: AllowedGender
  follower_start_from?: number
  follower_end_to?: number
  verified_kol?: boolean
  engagement_rate_start_from?: number
  engagement_rate_end_to?: number
  view_rate_start_from?: number
  view_rate_end_to?: number
  follower_growth_rate_start_from?: number
  follower_growth_rate_end_to?: number
  is_unlocked?: boolean
  include_collection_ids?: number[] | 'all'
  include_campaign_ids?: number[] | 'all'
  include_deep_report_ids?: number[] | 'all'
  customized_label_ids?: number[]
  min_collaboration_compensation?: number
  max_collaboration_compensation?: number
  min_quotation_price?: number
  max_quotation_price?: number
}

const kolAssetSchema = z.object({
  kol_name: z.string(),
  uuid: z.string(),
  dc_id_hashed: z.string(),
})

export const fetchKolAssetCatalogResponseSchema = listResourceSchema.extend({
  data: kolAssetSchema.array(),
})
export type FetchKolAssetCatalogResponse = z.infer<
  typeof fetchKolAssetCatalogResponseSchema
>

export interface QuickSearchKolAssetCatalogRequest {
  keyword: string
}

export const quickSearchKolAssetCatalogResponseSchema = z.object({
  data: quickSearchKolSchema.array(),
})
export type QuickSearchKolAssetCatalogResponse = z.infer<
  typeof quickSearchKolAssetCatalogResponseSchema
>

export interface FetchCollaborationRecordScoringRequest {
  kolId: string
}

const scoreSchema = z.object({
  ID: z.number(),
  UpdatedAt: z.string().datetime(),
  UserID: z.number(),
  User: z.object({
    Name: z.string(),
  }),
  ScoreValue: z.number(),
  Comment: z.string(),
})
export type Score = z.infer<typeof scoreSchema>

export const fetchCollaborationRecordScoringSchema = z.array(scoreSchema)
export type FetchCollaborationRecordScoringResponse = z.infer<
  typeof fetchCollaborationRecordScoringSchema
>

export interface DeleteCollaborationRecordScoringRequest {
  id: number
}

export interface AddCollaborationRecordScoringRequest {
  kolId: string
  score: {
    comment: string
    scoreValue: number
  }
}

export interface EditCollaborationRecordScoringRequest {
  id: number
  score: {
    comment: string
    scoreValue: number
  }
}

export interface FetchQuotationRequest {
  kolId: string
}

const quotationSchema = z.object({
  ID: z.number(),
  UserID: z.number(),
  Name: z.string(),
  Amount: z.number(),
  Date: z.string().datetime(),
})

export type Quotation = z.infer<typeof quotationSchema>
export const fetchQuotationResponseSchema = z.array(quotationSchema)
export type FetchQuotationResponse = z.infer<
  typeof fetchQuotationResponseSchema
>

export interface DeleteQuotationRequest {
  id: number
}

export interface QuotationPayload {
  amount: number
  date: string
  name: string
}

export interface AddQuotationRequest {
  kolId: string
  quotation: QuotationPayload
}

export interface EditQuotationRequest {
  id: number
  quotation: QuotationPayload
}

export interface FetchNoteRequest {
  kolId: string
}

const noteSchema = z.object({
  ID: z.number(),
  UpdatedAt: z.string().datetime(),
  UserID: z.number(),
  User: z.object({
    Name: z.string(),
  }),
  Note: z.string(),
  IsEdited: z.boolean(),
})
export type Note = z.infer<typeof noteSchema>
export const fetchNoteResponseSchema = z.array(noteSchema)
export type FetchNoteResponse = z.infer<typeof fetchNoteResponseSchema>

export interface DeleteNoteRequest {
  id: number
}

export interface NotePayload {
  note: string
}
export interface AddNoteRequest {
  note: NotePayload
  kolId: string
}

export interface EditNoteRequest {
  id: number
  note: NotePayload
}

const directoryBasicSearchFieldSchema = z.object({
  id: z.number().nullable(),
  visible: z.boolean().nullable(), // 免費 / 付費數據
  sortable: z.boolean().nullable(), // Table 可排序
})

export const directorySearchLeafFieldType = z.enum([
  'name', // 不能關閉，所以不會顯示在設定中，常駐排序第一個不能拖曳
  'country_code',
  'gender',
  'follower_level',
  'platforms',
  'customized_tag_names',
  'contact_info',
  'profession',
  'birthday',
  'tags',
  'collections',
  'campaigns',
  'reports',
  'collaboration_record',
  'footprint',
  'post_quotations',
  'video_quotations',
  'ig_statistics_social_platform_name',
  'fb_statistics_social_platform_name',
  'yt_statistics_social_platform_name',
  'tiktok_statistics_social_platform_name',
  'twitter_statistics_social_platform_name',
  'ig_statistics_k_score_3m',
  'ig_statistics_k_score_6m',
  'fb_statistics_k_score_3m',
  'fb_statistics_k_score_6m',
  'yt_statistics_k_score_3m',
  'yt_statistics_k_score_6m',
  'ig_statistics_reels_engagement_rate_3m',
  'ig_statistics_reels_engagement_rate_6m',
  'ig_statistics_reels_engagement_count_3m',
  'ig_statistics_reels_engagement_count_6m',
  'ig_statistics_like_count_3m',
  'ig_statistics_like_count_6m',
  'ig_statistics_comment_count_3m',
  'ig_statistics_comment_count_6m',
  'yt_statistics_shorts_view_count_3m',
  'yt_statistics_shorts_view_count_6m',
  'yt_statistics_shorts_view_rate_3m',
  'yt_statistics_shorts_view_rate_6m',
  'ig_statistics_primary_language',
  'fb_statistics_primary_language',
  'yt_statistics_primary_language',
  'ig_statistics_engagement_rate_3m',
  'ig_statistics_engagement_rate_6m',
  'fb_statistics_engagement_rate_3m',
  'fb_statistics_engagement_rate_6m',
  'yt_statistics_engagement_rate_3m',
  'yt_statistics_engagement_rate_6m',
  'tiktok_statistics_engagement_rate_3m',
  'tiktok_statistics_engagement_rate_6m',
  'twitter_statistics_engagement_rate_3m',
  'twitter_statistics_engagement_rate_6m',
  'ig_statistics_content_distribution_3m',
  'ig_statistics_content_distribution_6m',
  'fb_statistics_content_distribution_3m',
  'fb_statistics_content_distribution_6m',
  'yt_statistics_content_distribution_3m',
  'yt_statistics_content_distribution_6m',
  'ig_statistics_audience_region',
  'yt_statistics_audience_region',
  'ig_statistics_main_age',
  'fb_statistics_main_age',
  'yt_statistics_main_age',
  'tiktok_statistics_main_age',
  'ig_statistics_main_gender',
  'fb_statistics_main_gender',
  'yt_statistics_main_gender',
  'tiktok_statistics_main_gender',
  'ig_statistics_business_cooperation_ratio_3m',
  'ig_statistics_business_cooperation_ratio_6m',
  'fb_statistics_business_cooperation_ratio_3m',
  'fb_statistics_business_cooperation_ratio_6m',
  'yt_statistics_business_cooperation_ratio_3m',
  'yt_statistics_business_cooperation_ratio_6m',
  'ig_statistics_post_valuation_ratio_3m',
  'ig_statistics_post_valuation_ratio_6m',
  'fb_statistics_post_valuation_ratio_3m',
  'fb_statistics_post_valuation_ratio_6m',
  'yt_statistics_post_valuation_ratio_3m',
  'yt_statistics_post_valuation_ratio_6m',
  'ig_statistics_post_estimated_reach_count_3m',
  'ig_statistics_post_estimated_reach_count_6m',
  'fb_statistics_post_estimated_reach_count_3m',
  'fb_statistics_post_estimated_reach_count_6m',
  'ig_statistics_avg_engagement_count_3m',
  'ig_statistics_avg_engagement_count_6m',
  'fb_statistics_avg_engagement_count_3m',
  'fb_statistics_avg_engagement_count_6m',
  'yt_statistics_avg_engagement_count_3m',
  'yt_statistics_avg_engagement_count_6m',
  'tiktok_statistics_avg_engagement_count_3m',
  'tiktok_statistics_avg_engagement_count_6m',
  'twitter_statistics_avg_engagement_count_3m',
  'twitter_statistics_avg_engagement_count_6m',
  'fb_statistics_avg_share_count_3m',
  'fb_statistics_avg_share_count_6m',
  'ig_statistics_avg_like_count_3m',
  'ig_statistics_avg_like_count_6m',
  'fb_statistics_avg_like_count_3m',
  'fb_statistics_avg_like_count_6m',
  'yt_statistics_avg_like_count_3m',
  'yt_statistics_avg_like_count_6m',
  'tiktok_statistics_avg_like_count_3m',
  'tiktok_statistics_avg_like_count_6m',
  'twitter_statistics_avg_like_count_3m',
  'twitter_statistics_avg_like_count_6m',
  'ig_statistics_avg_comment_count_3m',
  'ig_statistics_avg_comment_count_6m',
  'fb_statistics_avg_comment_count_3m',
  'fb_statistics_avg_comment_count_6m',
  'yt_statistics_avg_comment_count_3m',
  'yt_statistics_avg_comment_count_6m',
  'tiktok_statistics_avg_comment_count_3m',
  'tiktok_statistics_avg_comment_count_6m',
  'twitter_statistics_avg_comment_count_3m',
  'twitter_statistics_avg_comment_count_6m',
  'ig_statistics_avg_view_count_3m',
  'ig_statistics_avg_view_count_6m',
  'fb_statistics_avg_view_count_3m',
  'fb_statistics_avg_view_count_6m',
  'yt_statistics_avg_view_count_3m',
  'yt_statistics_avg_view_count_6m',
  'tiktok_statistics_avg_view_count_3m',
  'tiktok_statistics_avg_view_count_6m',
  'twitter_statistics_avg_view_count_3m',
  'twitter_statistics_avg_view_count_6m',
  'ig_statistics_video_valuation_3m',
  'ig_statistics_video_valuation_6m',
  'fb_statistics_video_valuation_3m',
  'fb_statistics_video_valuation_6m',
  'yt_statistics_video_valuation_3m',
  'yt_statistics_video_valuation_6m',
  'tiktok_statistics_video_valuation_3m',
  'tiktok_statistics_video_valuation_6m',
  'ig_statistics_video_estimated_reach_count_3m',
  'ig_statistics_video_estimated_reach_count_6m',
  'fb_statistics_video_estimated_reach_count_3m',
  'fb_statistics_video_estimated_reach_count_6m',
  'yt_statistics_video_estimated_reach_count_3m',
  'yt_statistics_video_estimated_reach_count_6m',
  'ig_statistics_potential',
  'fb_statistics_potential',
  'yt_statistics_potential',
  'ig_statistics_comment_to_like_rate_3m',
  'ig_statistics_comment_to_like_rate_6m',
  'fb_statistics_comment_to_like_rate_3m',
  'fb_statistics_comment_to_like_rate_6m',
  'yt_statistics_comment_to_like_rate_3m',
  'yt_statistics_comment_to_like_rate_6m',
  'ig_statistics_mention_brand_3m',
  'ig_statistics_mention_brand_6m',
  'ig_statistics_mention_kol_3m',
  'ig_statistics_mention_kol_6m',
  'ig_statistics_latest_posts',
  'fb_statistics_latest_posts',
  'yt_statistics_latest_posts',
  'tiktok_statistics_latest_posts',
  'ig_statistics_post_count_3m',
  'ig_statistics_post_count_6m',
  'fb_statistics_post_count_3m',
  'fb_statistics_post_count_6m',
  'yt_statistics_post_count_3m',
  'yt_statistics_post_count_6m',
  'tiktok_statistics_post_count_3m',
  'tiktok_statistics_post_count_6m',
  'twitter_statistics_post_count_3m',
  'twitter_statistics_post_count_6m',
  'ig_statistics_fans_growth_count_3m',
  'ig_statistics_fans_growth_count_6m',
  'fb_statistics_fans_growth_count_3m',
  'fb_statistics_fans_growth_count_6m',
  'yt_statistics_fans_growth_count_3m',
  'yt_statistics_fans_growth_count_6m',
  'tiktok_statistics_fans_growth_count_3m',
  'tiktok_statistics_fans_growth_count_6m',
  'twitter_statistics_fans_growth_count_3m',
  'twitter_statistics_fans_growth_count_6m',
  'ig_statistics_fans_growth_rate_3m',
  'ig_statistics_fans_growth_rate_6m',
  'fb_statistics_fans_growth_rate_3m',
  'fb_statistics_fans_growth_rate_6m',
  'yt_statistics_fans_growth_rate_3m',
  'yt_statistics_fans_growth_rate_6m',
  'tiktok_statistics_fans_growth_rate_3m',
  'tiktok_statistics_fans_growth_rate_6m',
  'twitter_statistics_fans_growth_rate_3m',
  'twitter_statistics_fans_growth_rate_6m',
  'ig_statistics_post_frequency_3m',
  'ig_statistics_post_frequency_6m',
  'fb_statistics_post_frequency_3m',
  'fb_statistics_post_frequency_6m',
  'yt_statistics_post_frequency_3m',
  'yt_statistics_post_frequency_6m',
  'tiktok_statistics_post_frequency_3m',
  'tiktok_statistics_post_frequency_6m',
  'twitter_statistics_post_frequency_3m',
  'twitter_statistics_post_frequency_6m',
  'fb_statistics_live_view_count_3m',
  'fb_statistics_live_view_count_6m',
  'fb_statistics_live_view_rate_3m',
  'fb_statistics_live_view_rate_6m',
  'ig_statistics_similar_kols',
  'fb_statistics_similar_kols',
  'yt_statistics_similar_kols',
  'ig_statistics_audience_authenticity',
  'yt_statistics_shorts_estimated_reach_count_3m',
  'yt_statistics_shorts_estimated_reach_count_6m',
  'ig_statistics_kol_descriptions',
  'fb_statistics_kol_descriptions',
  'yt_statistics_kol_descriptions',
  'tiktok_statistics_kol_descriptions',
  'ig_statistics_view_rate_3m',
  'ig_statistics_view_rate_6m',
  'fb_statistics_view_rate_3m',
  'fb_statistics_view_rate_6m',
  'yt_statistics_view_rate_3m',
  'yt_statistics_view_rate_6m',
  'tiktok_statistics_view_rate_3m',
  'tiktok_statistics_view_rate_6m',
  'ig_statistics_follower_count',
  'fb_statistics_follower_count',
  'yt_statistics_follower_count',
  'tiktok_statistics_follower_count',
  'twitter_statistics_follower_count',
])
export type DirectorySearchLeafFieldType = z.infer<
  typeof directorySearchLeafFieldType
>

export const directoryBuiltinSearchFieldType = z.enum([
  'kol_basic',
  'cooperation_info',
  'ig_statistics',
  'fb_statistics',
  'yt_statistics',
  'tiktok_statistics',
  'twitter_statistics',
  'ig_statistics_3m',
  'ig_statistics_6m',
  'fb_statistics_3m',
  'fb_statistics_6m',
  'yt_statistics_3m',
  'yt_statistics_6m',
  'tiktok_statistics_3m',
  'tiktok_statistics_6m',
  'twitter_statistics_3m',
  'twitter_statistics_6m',
])

const directoryBuiltinSearchFieldBasicSchema =
  directoryBasicSearchFieldSchema.extend({
    type: z.union([
      directorySearchLeafFieldType,
      directoryBuiltinSearchFieldType,
    ]),
  })

const directoryBuiltinSearchLeafFieldSchema =
  directoryBuiltinSearchFieldBasicSchema.extend({
    id: z.number(),
    visible: z.boolean(),
    sortable: z.boolean(),
  })
type DirectoryBuiltinSearchLeafField = z.infer<
  typeof directoryBuiltinSearchLeafFieldSchema
>

export type DirectoryBuiltinSearchField = z.infer<
  typeof directoryBuiltinSearchFieldBasicSchema
> & {
  sub_fields: (DirectoryBuiltinSearchField | DirectoryBuiltinSearchLeafField)[]
}

const directoryBuiltinSearchFieldSchema: z.ZodType<DirectoryBuiltinSearchField> =
  directoryBuiltinSearchFieldBasicSchema.extend({
    sub_fields: z
      .lazy(() =>
        z.union([
          directoryBuiltinSearchFieldSchema,
          directoryBuiltinSearchLeafFieldSchema,
        ]),
      )
      .array(),
  })

const directoryCustomizedSearchLeafFieldSchema = z.object({
  id: z.number(),
  visible: z.literal(true),
  sortable: z.literal(false),
  type: z.string(),
})

export type DirectoryCustomizedSearchLeafField = z.infer<
  typeof directoryCustomizedSearchLeafFieldSchema
>

const directoryCustomizedSearchFieldSchema =
  directoryBasicSearchFieldSchema.extend({
    type: z.enum([
      'collection_customized_field',
      'brand_asset_hub_customized_field',
    ]),
    sub_fields: directoryCustomizedSearchLeafFieldSchema.array(),
  })

export type DirectoryCustomizedSearchField = z.infer<
  typeof directoryCustomizedSearchFieldSchema
>

const directorySearchFieldSchema = z.union([
  directoryBuiltinSearchFieldSchema,
  directoryCustomizedSearchFieldSchema,
])
export type DirectorySearchField = z.infer<typeof directorySearchFieldSchema>

const directorySearchLeafFieldSchema = z.union([
  directoryBuiltinSearchLeafFieldSchema,
  directoryCustomizedSearchLeafFieldSchema,
])
export type DirectorySearchLeafField = z.infer<
  typeof directorySearchLeafFieldSchema
>

const directorySearchFieldsSettingSchema = z.object({
  tree_data: directorySearchFieldSchema.array(),
  array: directorySearchLeafFieldSchema.array(),
})
export type DirectorySearchFieldsSetting = z.infer<
  typeof directorySearchFieldsSettingSchema
>

export const directorySearchFieldsSettingResponseSchema = z.object({
  data: directorySearchFieldsSettingSchema,
})
export type DirectorySearchFieldsSettingsResponse = z.infer<
  typeof directorySearchFieldsSettingResponseSchema
>

export interface KolCollectionBasicRequest {
  collectionId: number
}

export interface CreateKolCollectionCustomizedFieldRequest
  extends KolCollectionBasicRequest {
  type: string
}

export interface ChangeKolCollectionCustomizedFieldRequest
  extends CreateKolCollectionCustomizedFieldRequest {
  id: number
}

export interface DeleteKolCollectionCustomizedFieldRequest
  extends KolCollectionBasicRequest {
  id: number
}

export const contactTypeSchema = z.enum([
  'email',
  'phone',
  'line',
  'qq',
  'we-chat',
  'whatsapp',
])

export type ContactType = z.infer<typeof contactTypeSchema>

export const contactTypeI18nMap: Record<ContactType, I18nId> = {
  email: 'irm:title_email',
  phone: 'consult:field_phone',
  line: 'irm:title_line',
  qq: 'irm:title_qq',
  'we-chat': 'irm:title_wechat',
  whatsapp: 'irm:title_whatsapp',
}

const contactInfoSchema = z.object({
  /**@description 非廠商端新增的欄位 directory_contact_id 會是 0, e.g. kol, agent 新增 or 爬蟲過來 */
  directory_contact_id: z.number(),
  type: contactTypeSchema,
  value: z.string(),
  /**@description
   * - api spec: https://www.notion.so/ikala/102fb0834d6e807391b7d19b8a8467fa?pvs=4
   * - feature spec: https://www.notion.so/ikala/2e3c228462004a9c98fcc74d2144c86f?pvs=4#712789d981504e1a84e58418296173ca
   *
   * - directory: ad 新增
   * - user: kol 新增
   * - agent: agent 新增
   * - fb: 爬蟲過來
   * - '': 預設，後端預期應該不會出現
   */
  resource_type: z.enum(['directory', 'user', 'agent', 'fb', '']).optional(),
  is_default_contact: z.boolean().optional(),
  notes: z.string().optional(),
})

export type ContactInfo = z.infer<typeof contactInfoSchema>

export type UpdateContactInfo = Omit<
  ContactInfo,
  'directory_contact_id' | 'email_type'
>

export const fetchKolContactInfoResponseSchema = z.object({
  data: z.object({
    contact_infos: contactInfoSchema.array(),
  }),
})

export type FetchKolContactInfoRequest = {
  kolId: string
}

export type FetchKolContactInfoResponse = z.infer<
  typeof fetchKolContactInfoResponseSchema
>

export interface AddKolContactInfoRequest {
  kolId: string
  contactInfo: UpdateContactInfo
}

export interface EditKolContactInfoRequest {
  kolId: string
  contactInfoId: ContactInfo['directory_contact_id']
  contactInfo: UpdateContactInfo
}

export interface DeleteKolContactInfoRequest {
  kolId: string
  contactInfoId: ContactInfo['directory_contact_id']
}

export const filterResourceType = z.enum([
  'deep_report',
  'campaign',
  'collection',
])
export type FilterResourceType = z.infer<typeof filterResourceType>

export interface FetchFilterResourcesRequest {
  keyword?: string
  filter_resource_type: z.infer<typeof filterResourceType>
  page: number
}

const filterResource = z.object({ id: z.number(), name: z.string() })
export type FilterResource = z.infer<typeof filterResource>

export const fetchFilterResourcesResponseSchema = listResourceSchema.extend({
  data: filterResource.array(),
})
export type FetchFilterResourcesResponse = z.infer<
  typeof fetchFilterResourcesResponseSchema
>

const basicInfoSchema = z.object({
  birthday: z.string().date().or(z.literal('')),
  profession: z.string(),
  address: z.string(),
  customized_labels: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    }),
  ),
})

export type BasicInfo = z.infer<typeof basicInfoSchema>

export const fetchKolBasicInfoResponseSchema = z.object({
  data: z.object({
    basic_info: basicInfoSchema,
  }),
})

export type FetchKolBasicInfoRequest = {
  kolId: string
}

export type FetchKolBasicInfoResponse = z.infer<
  typeof fetchKolBasicInfoResponseSchema
>

export interface EditKolBasicInfoRequest {
  kolId: string
  basicInfo: BasicInfo
}

export const fetchCustomizedLabelsResponseSchema = z.object({
  data: z.object({ customized_labels: filterResource.array() }),
})
export type FetchCustomizedLabelsResponse = z.infer<
  typeof fetchCustomizedLabelsResponseSchema
>
