import { ThunkDispatch } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'
import { WorkspacesAPITagType } from '@/api/api'
import { searchApi } from '@/api/workspace-api/search-api'
import { workspacesApi } from '@/api/workspaces-api'
import { RootState } from '@/store/store'

const updateKolCollectionKolQueryData = (
  state: RootState,
  dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  kolIds: string[],
  isCollected: boolean,
): void => {
  const endpointsToUpdate = searchApi.util.selectInvalidatedBy(state, [
    WorkspacesAPITagType.KolCollectionKol,
  ])

  const workspaceApiEndpointsToUpdate = workspacesApi.util.selectInvalidatedBy(
    state,
    [WorkspacesAPITagType.InfluencerRanking],
  )

  endpointsToUpdate.forEach(({ endpointName, originalArgs }) => {
    switch (endpointName) {
      case 'kolRadarSearch':
      case 'searchSimilarKols':
        dispatch(
          searchApi.util.updateQueryData(
            endpointName,
            originalArgs,
            (draft) => {
              draft.list.forEach((kol) => {
                if (!kolIds.includes(kol.id)) {
                  return
                }

                kol.isCollectionKol = isCollected
              })
            },
          ),
        )
        break
    }
  })

  workspaceApiEndpointsToUpdate.forEach(({ endpointName, originalArgs }) => {
    if (endpointName === 'fetchAnalysisRanking') {
      dispatch(
        workspacesApi.util.updateQueryData(
          endpointName,
          originalArgs,
          (draft) => {
            draft.data.forEach((kol) => {
              if (kolIds.includes(kol.uuid)) {
                kol.isCollectionKol = isCollected
              }
            })
          },
        ),
      )
    }
  })
}

export default updateKolCollectionKolQueryData
