import { omit } from 'lodash-es'
import { UnknownAction } from 'redux'
import { Epic, ofType, StateObservable } from 'redux-observable'
import { from, Observable, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { AppContext } from '@/api/executor/app-context'
import { userApi } from '@/api/user-api'
import {
  communityFilterRangeDefaultMax,
  communityFilterRangeDefaultMin,
} from '@/constants/irm/community-filter-range-default'
import { RootState } from '@/store/store'
import { Action, ACTION_TYPES } from '@/types/redux/irm'
import { ampli } from '@/utils/ampli'

const fetchAllTags: Epic<UnknownAction, UnknownAction, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType(ACTION_TYPES.GET_ALL_TAGS),
    switchMap(() => {
      const workspaceId = userApi.endpoints.fetchUserStatus.select()(
        state$.value,
      )?.data?.currentWorkspaceId

      return from(
        AppContext.ApiExecutor.getIRMTags(undefined, workspaceId),
      ).pipe(
        map(({ data }) => {
          const payload = data.map((tag) => tag.customizedTag)
          return {
            type: ACTION_TYPES.SET_ALL_TAGS,
            payload,
          }
        }),
        catchError(() => {
          return of({
            type: ACTION_TYPES.SET_ALL_TAGS,
            payload: [],
          })
        }),
      )
    }),
  )

const fetchIRMTags: Epic<UnknownAction, UnknownAction, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType(ACTION_TYPES.GET_IRM_TAGS),
    switchMap(() => {
      const workspaceId = userApi.endpoints.fetchUserStatus.select()(
        state$.value,
      )?.data?.currentWorkspaceId

      return from(AppContext.ApiExecutor.getIRMTags(true, workspaceId)).pipe(
        map(({ data }) => {
          const payload = data.map((tag) => tag.customizedTag)
          return {
            type: ACTION_TYPES.SET_IRM_TAGS,
            payload,
          }
        }),
        catchError(() => {
          return of({
            type: ACTION_TYPES.SET_IRM_TAGS,
            payload: [],
          })
        }),
      )
    }),
  )

const fetchIRMKOLTags: Epic<UnknownAction, UnknownAction, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType(ACTION_TYPES.GET_KOL_TAGS),
    switchMap((action: Action['GET_KOL_TAGS']) => {
      const workspaceId = userApi.endpoints.fetchUserStatus.select()(
        state$.value,
      )?.data?.currentWorkspaceId

      return from(
        AppContext.ApiExecutor.getIRMKOLTags(action.payload, workspaceId),
      ).pipe(
        map(({ data }) => {
          const payload = data.map((tag) => tag.customizedTag)
          return {
            type: ACTION_TYPES.SET_KOL_TAGS,
            payload,
          }
        }),
        catchError(() => {
          return of({
            type: ACTION_TYPES.SET_KOL_TAGS,
            payload: [],
          })
        }),
      )
    }),
  )

const getCommunityKOLs: Epic<UnknownAction, UnknownAction, RootState> = (
  action$,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    ofType(ACTION_TYPES.GET_COMMUNITY_KOLS),
    switchMap((): Observable<Action['SET_COMMUNITY_KOLS']> => {
      const workspaceId = userApi.endpoints.fetchUserStatus.select()(
        state$.value,
      )?.data?.currentWorkspaceId

      return from(AppContext.ApiExecutor.getCommunity({}, workspaceId)).pipe(
        map(({ data }) => {
          return {
            type: ACTION_TYPES.SET_COMMUNITY_KOLS,
            payload: data,
          }
        }),
        catchError(() => {
          return of({
            type: ACTION_TYPES.SET_COMMUNITY_KOLS,
            payload: { total: 0, anchor: null, list: [] },
          })
        }),
      )
    }),
  )

const getCommunityKOLsByParams: Epic<
  UnknownAction,
  UnknownAction,
  RootState
> = (action$, state$: StateObservable<RootState>) =>
  action$.pipe(
    ofType(ACTION_TYPES.SET_SEARCH_PARAMS, ACTION_TYPES.CLEAN_SEARCH_PARAMS),
    switchMap((): Observable<Action['SET_COMMUNITY_KOLS']> => {
      const workspaceId = userApi.endpoints.fetchUserStatus.select()(
        state$.value,
      )?.data?.currentWorkspaceId
      const { searchParams } = state$.value.irm
      const offset = (searchParams.page - 1) * 10
      const reportIDs = searchParams.reports
        ?.map((report) => String(report.id))
        .join(',')
      const campaignIDs = searchParams.campaigns
        ?.map((campaign) => String(campaign.id))
        .join(',')
      const collectionIDs = searchParams.collections
        ?.map((collection) => String(collection.id))
        .join(',')

      const rangeMap = (min?: number, max?: number): undefined | string => {
        if (!min && !max) {
          return undefined
        } else {
          return `${min ?? communityFilterRangeDefaultMin}~${
            max ?? communityFilterRangeDefaultMax
          }`
        }
      }

      const engagementRateRange = rangeMap(
        searchParams.engagementRateMin,
        searchParams.engagementRateMax,
      )

      const fansUpRateRange = rangeMap(
        searchParams.fansUpRateMin,
        searchParams.fansUpRateMax,
      )

      const videoViewRateRange = rangeMap(
        searchParams.videoViewRateMin,
        searchParams.videoViewRateMax,
      )

      const params = omit(
        {
          offset,
          engagementRateRange,
          fansUpRateRange,
          videoViewRateRange,
          ...searchParams,
          reportIDs,
          campaignIDs,
          collectionIDs,
        },
        ['reports', 'campaigns', 'collections'],
      )

      const followerRanges = searchParams.followerRange?.split('~') ?? []

      ampli.filterInCommunity({
        followerStartFrom: followerRanges[0],
        followerEndTo: followerRanges[1],
        gender: searchParams.gender,
        countryCode: searchParams.countryCode,
        platform: searchParams.platform,
        followerRateStartFrom: searchParams.fansUpRateMin,
        followerRateEndTo: searchParams.fansUpRateMax,
        viewRateStart: searchParams.videoViewRateMin,
        viewRateEnd: searchParams.videoViewRateMax,
        interactiveRateBegin: searchParams.engagementRateMin,
        interactiveRateEnd: searchParams.engagementRateMax,
        campaignNames: searchParams.campaigns?.map((campaign) => campaign.name),
        reportNames: searchParams.reports?.map((report) => report.name),
        projectNames: searchParams.collections?.map(
          (collection) => collection.name,
        ),
        recommendType: searchParams.recommend,
        tags: searchParams.customizedTags,
        kolName: searchParams.kolName,
      })

      return from(
        AppContext.ApiExecutor.getCommunity(params, workspaceId),
      ).pipe(
        map(({ data }) => {
          const { total } = data

          if (total === 0) {
            ampli.filterInCommunityNoResult({
              followerStartFrom: followerRanges[0],
              followerEndTo: followerRanges[1],
              gender: searchParams.gender,
              countryCode: searchParams.countryCode,
              platform: searchParams.platform,
              followerRateStartFrom: searchParams.fansUpRateMin,
              followerRateEndTo: searchParams.fansUpRateMax,
              viewRateStart: searchParams.videoViewRateMin,
              viewRateEnd: searchParams.videoViewRateMax,
              interactiveRateBegin: searchParams.engagementRateMin,
              interactiveRateEnd: searchParams.engagementRateMax,
              campaignNames: searchParams.campaigns?.map(
                (campaign) => campaign.name,
              ),
              reportNames: searchParams.reports?.map((report) => report.name),
              projectNames: searchParams.collections?.map(
                (collection) => collection.name,
              ),
              recommendType: searchParams.recommend,
              tags: searchParams.customizedTags,
              kolName: searchParams.kolName,
            })
          }

          return {
            type: ACTION_TYPES.SET_COMMUNITY_KOLS,
            payload: data,
          }
        }),
        catchError(() => {
          ampli.filterInCommunityFailed({
            followerStartFrom: followerRanges[0],
            followerEndTo: followerRanges[1],
            gender: searchParams.gender,
            countryCode: searchParams.countryCode,
            platform: searchParams.platform,
            followerRateStartFrom: searchParams.fansUpRateMin,
            followerRateEndTo: searchParams.fansUpRateMax,
            viewRateStart: searchParams.videoViewRateMin,
            viewRateEnd: searchParams.videoViewRateMax,
            interactiveRateBegin: searchParams.engagementRateMin,
            interactiveRateEnd: searchParams.engagementRateMax,
            campaignNames: searchParams.campaigns?.map(
              (campaign) => campaign.name,
            ),
            reportNames: searchParams.reports?.map((report) => report.name),
            projectNames: searchParams.collections?.map(
              (collection) => collection.name,
            ),
            recommendType: searchParams.recommend,
            tags: searchParams.customizedTags,
            kolName: searchParams.kolName,
          })

          return of({
            type: ACTION_TYPES.SET_COMMUNITY_KOLS,
            payload: { total: 0, anchor: null, list: [] },
          })
        }),
      )
    }),
  )

const getCommunitySetting: Epic<UnknownAction, UnknownAction, RootState> = (
  action$,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    ofType(ACTION_TYPES.GET_COMMUNITY_SETTING),
    switchMap(() => {
      const workspaceId = userApi.endpoints.fetchUserStatus.select()(
        state$.value,
      )?.data?.currentWorkspaceId

      return from(AppContext.ApiExecutor.getCommunitySetting(workspaceId)).pipe(
        map(({ data }) => {
          const payload = data.resultDisplay
          return {
            type: ACTION_TYPES.SET_RESULT_DISPLAY,
            payload,
          }
        }),
        catchError(() => {
          return of({
            type: ACTION_TYPES.SET_RESULT_DISPLAY,
            payload: {},
          })
        }),
      )
    }),
  )

const irmEpics = [
  fetchAllTags,
  fetchIRMTags,
  fetchIRMKOLTags,
  getCommunityKOLs,
  getCommunityKOLsByParams,
  getCommunitySetting,
]

export default irmEpics
